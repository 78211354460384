.button {
  @include typi(s1);
  display: inline-block;
  margin-bottom: vr(1);
  text-decoration: none;
  padding: .5rem vr(1);
  border-radius: 9px;
  transition: background-color .1s;
  text-transform: uppercase;
  text-align: center;
  background-color: $primary-color;
  color: #fff;
  border: 1px solid $primary-color;
  &:hover,
  &:focus {
    background-color: #fff;
    color: $primary-color;
    border: 1px solid $primary-color;
  }
}

