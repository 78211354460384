body, html {
    min-height: 100vh;
}
body {
    position: relative;
  @media only screen and (min-width: em-calc(500)) {
    padding-bottom: 6rem;
  }
  @media only screen and (max-width: em-calc(499)) {
    padding-bottom: rem-calc(67);
  }
}


.more {
  border: 0;
  text-align: center;
  display: block;
  color: $body-font-color;
  &:before {
    content: '';
    @extend .boto-mes-info;
    display: block;
    margin: 0 auto vr(.25);
  }
  &:hover {
    text-decoration: underline;
  }
  span {
    @include visually-hidden;
  }
}

// paràgraf introducció
p.intro {
  &:before {
    content: '';
    @extend .fletxa-claus-exit;
    margin-bottom: vr(1);
    @media #{$medium-up} {
      display: block;
    }
  }
}

// text en dues columnes
.cols {
  @media #{$medium-up} {
    column-count: 2;
    margin-bottom: vr(1);
  }
}


// figure
figure {
  text-align: center;
  img {
    margin: 0 0 vr(.5);
  }
}
figcaption {
  @include bold;
  @include typi(s1);
  // text-align: left;
  // padding: vr(.5) 0;
}

// paràgraf destacat
.dest {
  @include typi(t3);
  padding: vr(.5);
  background-color: #e9e9e9;
  border-left: vr(1) solid $primary-color;
}

// baixar arxiu destacat
// .dl {
//   @include typi(s1);
//   background-color: #e9e9e9;
//   padding: vr(.25) vr(.5);
//   position: relative;
//   &:before {
//     content: '';
//     @extend .baixar-destacat;
//     display: block;
//     position: absolute;
//     left: -21px;
//     top: 50%;
//     margin-top: -8px;
//   }
//   a {
//     border: 0;
//     &:hover {
//       text-decoration: underline;
//     }
//   }
//   border-left: 28px solid $primary-color;
// }

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 0 vr(.5);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.menu-button__text {
    color: $primary-color;
}
