.cookies {
    @include typi(s1);
    background: $secondary-color;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: vr(1);
    padding-bottom: vr(1);
    p {
        color: #000;
        margin-bottom: 0;
    }

    a {
        color: #fff;
        text-decoration: none;
        white-space: nowrap;
        border-bottom: 1px solid #fff;
        &:hover {
            border-bottom: 0;
        }
    }

    .button {
        display: block;
        color: #fff;
        width: 10rem;
        margin: vr(1) auto 0;
        border: 0;
        @media #{$medium-up} {
            margin-top: vr(.5);
        }
        &:hover {
            color: $primary-color;
        }
    }
}
