.main-header {
	&__top {
		padding: rem-calc(18) 0 rem-calc(16);
		//background-color: $primary-color;
        background-color: #fff;
		margin-bottom: 6px
	}
	&__heading {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 0;
		background-color: gray;
		height: rem-calc(325);
		margin-bottom: vr(1.5);
		display: table;
		width: 100%;
		.home & {
			background-image: url('img/foto-portada.jpg');
            background-position: 0 0%;
			@media #{$retina} {
				background-image: url('img/foto-portada@2x.jpg');
			}
		}
		.franquicia-integral & {
			background-image: url('img/foto-franquicia-caprabo.jpg');
            background-position: 0 0%;
			@media #{$retina} {
				background-image: url('img/foto-franquicia-caprabo@2x.jpg');
			}
		}
		.formularis & {
			background-image: url('img/foto-formularis.jpg');
			@media #{$retina} {
				background-image: url('img/foto-formularis@2x.jpg');
			}
		}
		.caprabo-rapid & {
			background-image: url('img/foto-caprabo-rapid.jpg');
            background-position: 0% 50%;
			@media #{$retina} {
				background-image: url('img/foto-caprabo-rapid@2x.jpg');
			}
		}
		.aliprox & {
			background-image: url('img/foto-aliprox.jpg');
			@media #{$retina} {
				background-image: url('img/foto-aliprox@2x.jpg');
			}
		}
		.row {
			display: table-row;
			> div {
				display: table-cell;
				vertical-align: middle;
				float: none;
			}
		}
		@media #{$medium-up} {
			height: vr(20);
		}
	}
	&__title {
		color: #fff;
		text-align: center;

	}
	&__lead {
		color: #fff;
		text-align: center;
        font-size: 1.3em;
	}
}
.site-title {
	float: left;
    width: auto;
    img {
        width: 100%;
    }
    @media screen and (max-width: 450px) {
        width: 50%;

    }
}
