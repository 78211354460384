.main-footer {
	@include typi(s2);
 // background-color: #d8d8d8;
 background: #021727;

  padding-top: 0;
  margin-top: vr(2);
  text-align: center;
  width: 100%;
  img {
    margin-bottom: vr(.5);
    @media #{$medium-up} {
     display: inline-block;
     margin: 0 vr(1) 0 0;
    }
  }
  p {
    @media #{$medium-up} {
     display: inline-block;
    }
  }
  a {
    color: $primary-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media only screen and (min-width: em-calc(500)) {
    position: absolute;
    bottom: 0;
  }
}

.copy-footer {
    padding: 20px 25px;
    display: flex;
    align-items: center;
    text-align: left;
    .logo-footer {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .copy-txt {
        width: 59%;
        ul{
            margin-top: 0;
            margin-bottom: 10px;
            li{
                list-style: none;
                display: inline;
                color: #fff;
                a{
                    color: #fff;
                }
            }
        }
    }
    .social-iconos {
        width: 22%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 10px;
        img {
            width: 40px;
            height: auto;
          }
      }
  }
