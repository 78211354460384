// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$boto-mes-info-name: 'boto-mes-info';
$boto-mes-info-x: 287px;
$boto-mes-info-y: 169px;
$boto-mes-info-offset-x: -287px;
$boto-mes-info-offset-y: -169px;
$boto-mes-info-width: 22px;
$boto-mes-info-height: 23px;
$boto-mes-info-total-width: 346px;
$boto-mes-info-total-height: 248px;
$boto-mes-info-image: 'img/spritesheet.png?1499174236134';
$boto-mes-info: (287px, 169px, -287px, -169px, 22px, 23px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'boto-mes-info', );
$clau-acces-name: 'clau-acces';
$clau-acces-x: 309px;
$clau-acces-y: 169px;
$clau-acces-offset-x: -309px;
$clau-acces-offset-y: -169px;
$clau-acces-width: 32px;
$clau-acces-height: 15px;
$clau-acces-total-width: 346px;
$clau-acces-total-height: 248px;
$clau-acces-image: 'img/spritesheet.png?1499174236134';
$clau-acces: (309px, 169px, -309px, -169px, 32px, 15px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'clau-acces', );
$fletxa-claus-exit-name: 'fletxa-claus-exit';
$fletxa-claus-exit-x: 287px;
$fletxa-claus-exit-y: 49px;
$fletxa-claus-exit-offset-x: -287px;
$fletxa-claus-exit-offset-y: -49px;
$fletxa-claus-exit-width: 53px;
$fletxa-claus-exit-height: 53px;
$fletxa-claus-exit-total-width: 346px;
$fletxa-claus-exit-total-height: 248px;
$fletxa-claus-exit-image: 'img/spritesheet.png?1499174236134';
$fletxa-claus-exit: (287px, 49px, -287px, -49px, 53px, 53px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'fletxa-claus-exit', );
$icon-dades-franquicies-name: 'icon-dades-franquicies';
$icon-dades-franquicies-x: 287px;
$icon-dades-franquicies-y: 102px;
$icon-dades-franquicies-offset-x: -287px;
$icon-dades-franquicies-offset-y: -102px;
$icon-dades-franquicies-width: 44px;
$icon-dades-franquicies-height: 42px;
$icon-dades-franquicies-total-width: 346px;
$icon-dades-franquicies-total-height: 248px;
$icon-dades-franquicies-image: 'img/spritesheet.png?1499174236134';
$icon-dades-franquicies: (287px, 102px, -287px, -102px, 44px, 42px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-dades-franquicies', );
$icon-dades-punts-venda-name: 'icon-dades-punts-venda';
$icon-dades-punts-venda-x: 110px;
$icon-dades-punts-venda-y: 129px;
$icon-dades-punts-venda-offset-x: -110px;
$icon-dades-punts-venda-offset-y: -129px;
$icon-dades-punts-venda-width: 67px;
$icon-dades-punts-venda-height: 53px;
$icon-dades-punts-venda-total-width: 346px;
$icon-dades-punts-venda-total-height: 248px;
$icon-dades-punts-venda-image: 'img/spritesheet.png?1499174236134';
$icon-dades-punts-venda: (110px, 129px, -110px, -129px, 67px, 53px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-dades-punts-venda', );
$icon-demanem-emprenedor-name: 'icon-demanem-emprenedor';
$icon-demanem-emprenedor-x: 53px;
$icon-demanem-emprenedor-y: 129px;
$icon-demanem-emprenedor-offset-x: -53px;
$icon-demanem-emprenedor-offset-y: -129px;
$icon-demanem-emprenedor-width: 57px;
$icon-demanem-emprenedor-height: 66px;
$icon-demanem-emprenedor-total-width: 346px;
$icon-demanem-emprenedor-total-height: 248px;
$icon-demanem-emprenedor-image: 'img/spritesheet.png?1499174236134';
$icon-demanem-emprenedor: (53px, 129px, -53px, -129px, 57px, 66px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-demanem-emprenedor', );
$icon-demanem-fidelitat-name: 'icon-demanem-fidelitat';
$icon-demanem-fidelitat-x: 287px;
$icon-demanem-fidelitat-y: 0px;
$icon-demanem-fidelitat-offset-x: -287px;
$icon-demanem-fidelitat-offset-y: 0px;
$icon-demanem-fidelitat-width: 59px;
$icon-demanem-fidelitat-height: 49px;
$icon-demanem-fidelitat-total-width: 346px;
$icon-demanem-fidelitat-total-height: 248px;
$icon-demanem-fidelitat-image: 'img/spritesheet.png?1499174236134';
$icon-demanem-fidelitat: (287px, 0px, -287px, 0px, 59px, 49px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-demanem-fidelitat', );
$icon-demanem-valors-name: 'icon-demanem-valors';
$icon-demanem-valors-x: 0px;
$icon-demanem-valors-y: 201px;
$icon-demanem-valors-offset-x: 0px;
$icon-demanem-valors-offset-y: -201px;
$icon-demanem-valors-width: 69px;
$icon-demanem-valors-height: 47px;
$icon-demanem-valors-total-width: 346px;
$icon-demanem-valors-total-height: 248px;
$icon-demanem-valors-image: 'img/spritesheet.png?1499174236134';
$icon-demanem-valors: (0px, 201px, 0px, -201px, 69px, 47px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-demanem-valors', );
$icon-diferencia-experiencia-name: 'icon-diferencia-experiencia';
$icon-diferencia-experiencia-x: 0px;
$icon-diferencia-experiencia-y: 0px;
$icon-diferencia-experiencia-offset-x: 0px;
$icon-diferencia-experiencia-offset-y: 0px;
$icon-diferencia-experiencia-width: 74px;
$icon-diferencia-experiencia-height: 72px;
$icon-diferencia-experiencia-total-width: 346px;
$icon-diferencia-experiencia-total-height: 248px;
$icon-diferencia-experiencia-image: 'img/spritesheet.png?1499174236134';
$icon-diferencia-experiencia: (0px, 0px, 0px, 0px, 74px, 72px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-diferencia-experiencia', );
$icon-diferencia-fidelitzaci-name: 'icon-diferencia-fidelitzaci';
$icon-diferencia-fidelitzaci-x: 219px;
$icon-diferencia-fidelitzaci-y: 0px;
$icon-diferencia-fidelitzaci-offset-x: -219px;
$icon-diferencia-fidelitzaci-offset-y: 0px;
$icon-diferencia-fidelitzaci-width: 68px;
$icon-diferencia-fidelitzaci-height: 52px;
$icon-diferencia-fidelitzaci-total-width: 346px;
$icon-diferencia-fidelitzaci-total-height: 248px;
$icon-diferencia-fidelitzaci-image: 'img/spritesheet.png?1499174236134';
$icon-diferencia-fidelitzaci: (219px, 0px, -219px, 0px, 68px, 52px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-diferencia-fidelitzaci', );
$icon-diferencia-innovacio-name: 'icon-diferencia-innovacio';
$icon-diferencia-innovacio-x: 74px;
$icon-diferencia-innovacio-y: 0px;
$icon-diferencia-innovacio-offset-x: -74px;
$icon-diferencia-innovacio-offset-y: 0px;
$icon-diferencia-innovacio-width: 66px;
$icon-diferencia-innovacio-height: 71px;
$icon-diferencia-innovacio-total-width: 346px;
$icon-diferencia-innovacio-total-height: 248px;
$icon-diferencia-innovacio-image: 'img/spritesheet.png?1499174236134';
$icon-diferencia-innovacio: (74px, 0px, -74px, 0px, 66px, 71px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-diferencia-innovacio', );
$icon-diferencia-logistica-name: 'icon-diferencia-logistica';
$icon-diferencia-logistica-x: 140px;
$icon-diferencia-logistica-y: 0px;
$icon-diferencia-logistica-offset-x: -140px;
$icon-diferencia-logistica-offset-y: 0px;
$icon-diferencia-logistica-width: 79px;
$icon-diferencia-logistica-height: 54px;
$icon-diferencia-logistica-total-width: 346px;
$icon-diferencia-logistica-total-height: 248px;
$icon-diferencia-logistica-image: 'img/spritesheet.png?1499174236134';
$icon-diferencia-logistica: (140px, 0px, -140px, 0px, 79px, 54px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-diferencia-logistica', );
$icon-diferencia-posicionament-name: 'icon-diferencia-posicionament';
$icon-diferencia-posicionament-x: 219px;
$icon-diferencia-posicionament-y: 52px;
$icon-diferencia-posicionament-offset-x: -219px;
$icon-diferencia-posicionament-offset-y: -52px;
$icon-diferencia-posicionament-width: 58px;
$icon-diferencia-posicionament-height: 58px;
$icon-diferencia-posicionament-total-width: 346px;
$icon-diferencia-posicionament-total-height: 248px;
$icon-diferencia-posicionament-image: 'img/spritesheet.png?1499174236134';
$icon-diferencia-posicionament: (219px, 52px, -219px, -52px, 58px, 58px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-diferencia-posicionament', );
$icon-error-form-name: 'icon-error-form';
$icon-error-form-x: 287px;
$icon-error-form-y: 226px;
$icon-error-form-offset-x: -287px;
$icon-error-form-offset-y: -226px;
$icon-error-form-width: 19px;
$icon-error-form-height: 17px;
$icon-error-form-total-width: 346px;
$icon-error-form-total-height: 248px;
$icon-error-form-image: 'img/spritesheet.png?1499174236134';
$icon-error-form: (287px, 226px, -287px, -226px, 19px, 17px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-error-form', );
$icon-oferim-asessorament-name: 'icon-oferim-asessorament';
$icon-oferim-asessorament-x: 140px;
$icon-oferim-asessorament-y: 54px;
$icon-oferim-asessorament-offset-x: -140px;
$icon-oferim-asessorament-offset-y: -54px;
$icon-oferim-asessorament-width: 70px;
$icon-oferim-asessorament-height: 59px;
$icon-oferim-asessorament-total-width: 346px;
$icon-oferim-asessorament-total-height: 248px;
$icon-oferim-asessorament-image: 'img/spritesheet.png?1499174236134';
$icon-oferim-asessorament: (140px, 54px, -140px, -54px, 70px, 59px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-oferim-asessorament', );
$icon-oferim-marca-name: 'icon-oferim-marca';
$icon-oferim-marca-x: 0px;
$icon-oferim-marca-y: 129px;
$icon-oferim-marca-offset-x: 0px;
$icon-oferim-marca-offset-y: -129px;
$icon-oferim-marca-width: 53px;
$icon-oferim-marca-height: 72px;
$icon-oferim-marca-total-width: 346px;
$icon-oferim-marca-total-height: 248px;
$icon-oferim-marca-image: 'img/spritesheet.png?1499174236134';
$icon-oferim-marca: (0px, 129px, 0px, -129px, 53px, 72px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-oferim-marca', );
$icon-oferim-proximitat-name: 'icon-oferim-proximitat';
$icon-oferim-proximitat-x: 219px;
$icon-oferim-proximitat-y: 110px;
$icon-oferim-proximitat-offset-x: -219px;
$icon-oferim-proximitat-offset-y: -110px;
$icon-oferim-proximitat-width: 56px;
$icon-oferim-proximitat-height: 57px;
$icon-oferim-proximitat-total-width: 346px;
$icon-oferim-proximitat-total-height: 248px;
$icon-oferim-proximitat-image: 'img/spritesheet.png?1499174236134';
$icon-oferim-proximitat: (219px, 110px, -219px, -110px, 56px, 57px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-oferim-proximitat', );
$icon-oferim-referencies-name: 'icon-oferim-referencies';
$icon-oferim-referencies-x: 0px;
$icon-oferim-referencies-y: 72px;
$icon-oferim-referencies-offset-x: 0px;
$icon-oferim-referencies-offset-y: -72px;
$icon-oferim-referencies-width: 77px;
$icon-oferim-referencies-height: 57px;
$icon-oferim-referencies-total-width: 346px;
$icon-oferim-referencies-total-height: 248px;
$icon-oferim-referencies-image: 'img/spritesheet.png?1499174236134';
$icon-oferim-referencies: (0px, 72px, 0px, -72px, 77px, 57px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-oferim-referencies', );
$icon-oferim-rendibilitat-name: 'icon-oferim-rendibilitat';
$icon-oferim-rendibilitat-x: 77px;
$icon-oferim-rendibilitat-y: 72px;
$icon-oferim-rendibilitat-offset-x: -77px;
$icon-oferim-rendibilitat-offset-y: -72px;
$icon-oferim-rendibilitat-width: 53px;
$icon-oferim-rendibilitat-height: 56px;
$icon-oferim-rendibilitat-total-width: 346px;
$icon-oferim-rendibilitat-total-height: 248px;
$icon-oferim-rendibilitat-image: 'img/spritesheet.png?1499174236134';
$icon-oferim-rendibilitat: (77px, 72px, -77px, -72px, 53px, 56px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'icon-oferim-rendibilitat', );
$logo-barra-capraboacasa-name: 'logo-barra-capraboacasa';
$logo-barra-capraboacasa-x: 314px;
$logo-barra-capraboacasa-y: 144px;
$logo-barra-capraboacasa-offset-x: -314px;
$logo-barra-capraboacasa-offset-y: -144px;
$logo-barra-capraboacasa-width: 27px;
$logo-barra-capraboacasa-height: 25px;
$logo-barra-capraboacasa-total-width: 346px;
$logo-barra-capraboacasa-total-height: 248px;
$logo-barra-capraboacasa-image: 'img/spritesheet.png?1499174236134';
$logo-barra-capraboacasa: (314px, 144px, -314px, -144px, 27px, 25px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'logo-barra-capraboacasa', );
$logo-barra-chefcaprabo-name: 'logo-barra-chefcaprabo';
$logo-barra-chefcaprabo-x: 287px;
$logo-barra-chefcaprabo-y: 212px;
$logo-barra-chefcaprabo-offset-x: -287px;
$logo-barra-chefcaprabo-offset-y: -212px;
$logo-barra-chefcaprabo-width: 27px;
$logo-barra-chefcaprabo-height: 14px;
$logo-barra-chefcaprabo-total-width: 346px;
$logo-barra-chefcaprabo-total-height: 248px;
$logo-barra-chefcaprabo-image: 'img/spritesheet.png?1499174236134';
$logo-barra-chefcaprabo: (287px, 212px, -287px, -212px, 27px, 14px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'logo-barra-chefcaprabo', );
$logo-barra-matrona-name: 'logo-barra-matrona';
$logo-barra-matrona-x: 307px;
$logo-barra-matrona-y: 192px;
$logo-barra-matrona-offset-x: -307px;
$logo-barra-matrona-offset-y: -192px;
$logo-barra-matrona-width: 23px;
$logo-barra-matrona-height: 17px;
$logo-barra-matrona-total-width: 346px;
$logo-barra-matrona-total-height: 248px;
$logo-barra-matrona-image: 'img/spritesheet.png?1499174236134';
$logo-barra-matrona: (307px, 192px, -307px, -192px, 23px, 17px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'logo-barra-matrona', );
$logo-barra-miclub-caprabo-name: 'logo-barra-miclub-caprabo';
$logo-barra-miclub-caprabo-x: 287px;
$logo-barra-miclub-caprabo-y: 144px;
$logo-barra-miclub-caprabo-offset-x: -287px;
$logo-barra-miclub-caprabo-offset-y: -144px;
$logo-barra-miclub-caprabo-width: 27px;
$logo-barra-miclub-caprabo-height: 25px;
$logo-barra-miclub-caprabo-total-width: 346px;
$logo-barra-miclub-caprabo-total-height: 248px;
$logo-barra-miclub-caprabo-image: 'img/spritesheet.png?1499174236134';
$logo-barra-miclub-caprabo: (287px, 144px, -287px, -144px, 27px, 25px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'logo-barra-miclub-caprabo', );
$logo-barra-viajes-caprabo-name: 'logo-barra-viajes-caprabo';
$logo-barra-viajes-caprabo-x: 287px;
$logo-barra-viajes-caprabo-y: 192px;
$logo-barra-viajes-caprabo-offset-x: -287px;
$logo-barra-viajes-caprabo-offset-y: -192px;
$logo-barra-viajes-caprabo-width: 20px;
$logo-barra-viajes-caprabo-height: 20px;
$logo-barra-viajes-caprabo-total-width: 346px;
$logo-barra-viajes-caprabo-total-height: 248px;
$logo-barra-viajes-caprabo-image: 'img/spritesheet.png?1499174236134';
$logo-barra-viajes-caprabo: (287px, 192px, -287px, -192px, 20px, 20px, 346px, 248px, 'img/spritesheet.png?1499174236134', 'logo-barra-viajes-caprabo', );
$boto-mes-info-2x-name: 'boto-mes-info@2x';
$boto-mes-info-2x-x: 574px;
$boto-mes-info-2x-y: 338px;
$boto-mes-info-2x-offset-x: -574px;
$boto-mes-info-2x-offset-y: -338px;
$boto-mes-info-2x-width: 44px;
$boto-mes-info-2x-height: 46px;
$boto-mes-info-2x-total-width: 692px;
$boto-mes-info-2x-total-height: 496px;
$boto-mes-info-2x-image: 'img/spritesheet@2x.png?1499174236134';
$boto-mes-info-2x: (574px, 338px, -574px, -338px, 44px, 46px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'boto-mes-info@2x', );
$clau-acces-2x-name: 'clau-acces@2x';
$clau-acces-2x-x: 618px;
$clau-acces-2x-y: 338px;
$clau-acces-2x-offset-x: -618px;
$clau-acces-2x-offset-y: -338px;
$clau-acces-2x-width: 64px;
$clau-acces-2x-height: 30px;
$clau-acces-2x-total-width: 692px;
$clau-acces-2x-total-height: 496px;
$clau-acces-2x-image: 'img/spritesheet@2x.png?1499174236134';
$clau-acces-2x: (618px, 338px, -618px, -338px, 64px, 30px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'clau-acces@2x', );
$fletxa-claus-exit-2x-name: 'fletxa-claus-exit@2x';
$fletxa-claus-exit-2x-x: 574px;
$fletxa-claus-exit-2x-y: 98px;
$fletxa-claus-exit-2x-offset-x: -574px;
$fletxa-claus-exit-2x-offset-y: -98px;
$fletxa-claus-exit-2x-width: 106px;
$fletxa-claus-exit-2x-height: 106px;
$fletxa-claus-exit-2x-total-width: 692px;
$fletxa-claus-exit-2x-total-height: 496px;
$fletxa-claus-exit-2x-image: 'img/spritesheet@2x.png?1499174236134';
$fletxa-claus-exit-2x: (574px, 98px, -574px, -98px, 106px, 106px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'fletxa-claus-exit@2x', );
$icon-dades-franquicies-2x-name: 'icon-dades-franquicies@2x';
$icon-dades-franquicies-2x-x: 574px;
$icon-dades-franquicies-2x-y: 204px;
$icon-dades-franquicies-2x-offset-x: -574px;
$icon-dades-franquicies-2x-offset-y: -204px;
$icon-dades-franquicies-2x-width: 88px;
$icon-dades-franquicies-2x-height: 84px;
$icon-dades-franquicies-2x-total-width: 692px;
$icon-dades-franquicies-2x-total-height: 496px;
$icon-dades-franquicies-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-dades-franquicies-2x: (574px, 204px, -574px, -204px, 88px, 84px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-dades-franquicies@2x', );
$icon-dades-punts-venda-2x-name: 'icon-dades-punts-venda@2x';
$icon-dades-punts-venda-2x-x: 220px;
$icon-dades-punts-venda-2x-y: 258px;
$icon-dades-punts-venda-2x-offset-x: -220px;
$icon-dades-punts-venda-2x-offset-y: -258px;
$icon-dades-punts-venda-2x-width: 134px;
$icon-dades-punts-venda-2x-height: 106px;
$icon-dades-punts-venda-2x-total-width: 692px;
$icon-dades-punts-venda-2x-total-height: 496px;
$icon-dades-punts-venda-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-dades-punts-venda-2x: (220px, 258px, -220px, -258px, 134px, 106px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-dades-punts-venda@2x', );
$icon-demanem-emprenedor-2x-name: 'icon-demanem-emprenedor@2x';
$icon-demanem-emprenedor-2x-x: 106px;
$icon-demanem-emprenedor-2x-y: 258px;
$icon-demanem-emprenedor-2x-offset-x: -106px;
$icon-demanem-emprenedor-2x-offset-y: -258px;
$icon-demanem-emprenedor-2x-width: 114px;
$icon-demanem-emprenedor-2x-height: 132px;
$icon-demanem-emprenedor-2x-total-width: 692px;
$icon-demanem-emprenedor-2x-total-height: 496px;
$icon-demanem-emprenedor-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-demanem-emprenedor-2x: (106px, 258px, -106px, -258px, 114px, 132px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-demanem-emprenedor@2x', );
$icon-demanem-fidelitat-2x-name: 'icon-demanem-fidelitat@2x';
$icon-demanem-fidelitat-2x-x: 574px;
$icon-demanem-fidelitat-2x-y: 0px;
$icon-demanem-fidelitat-2x-offset-x: -574px;
$icon-demanem-fidelitat-2x-offset-y: 0px;
$icon-demanem-fidelitat-2x-width: 118px;
$icon-demanem-fidelitat-2x-height: 98px;
$icon-demanem-fidelitat-2x-total-width: 692px;
$icon-demanem-fidelitat-2x-total-height: 496px;
$icon-demanem-fidelitat-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-demanem-fidelitat-2x: (574px, 0px, -574px, 0px, 118px, 98px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-demanem-fidelitat@2x', );
$icon-demanem-valors-2x-name: 'icon-demanem-valors@2x';
$icon-demanem-valors-2x-x: 0px;
$icon-demanem-valors-2x-y: 402px;
$icon-demanem-valors-2x-offset-x: 0px;
$icon-demanem-valors-2x-offset-y: -402px;
$icon-demanem-valors-2x-width: 138px;
$icon-demanem-valors-2x-height: 94px;
$icon-demanem-valors-2x-total-width: 692px;
$icon-demanem-valors-2x-total-height: 496px;
$icon-demanem-valors-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-demanem-valors-2x: (0px, 402px, 0px, -402px, 138px, 94px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-demanem-valors@2x', );
$icon-diferencia-experiencia-2x-name: 'icon-diferencia-experiencia@2x';
$icon-diferencia-experiencia-2x-x: 0px;
$icon-diferencia-experiencia-2x-y: 0px;
$icon-diferencia-experiencia-2x-offset-x: 0px;
$icon-diferencia-experiencia-2x-offset-y: 0px;
$icon-diferencia-experiencia-2x-width: 148px;
$icon-diferencia-experiencia-2x-height: 144px;
$icon-diferencia-experiencia-2x-total-width: 692px;
$icon-diferencia-experiencia-2x-total-height: 496px;
$icon-diferencia-experiencia-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-diferencia-experiencia-2x: (0px, 0px, 0px, 0px, 148px, 144px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-diferencia-experiencia@2x', );
$icon-diferencia-fidelitzaci-2x-name: 'icon-diferencia-fidelitzaci@2x';
$icon-diferencia-fidelitzaci-2x-x: 438px;
$icon-diferencia-fidelitzaci-2x-y: 0px;
$icon-diferencia-fidelitzaci-2x-offset-x: -438px;
$icon-diferencia-fidelitzaci-2x-offset-y: 0px;
$icon-diferencia-fidelitzaci-2x-width: 136px;
$icon-diferencia-fidelitzaci-2x-height: 104px;
$icon-diferencia-fidelitzaci-2x-total-width: 692px;
$icon-diferencia-fidelitzaci-2x-total-height: 496px;
$icon-diferencia-fidelitzaci-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-diferencia-fidelitzaci-2x: (438px, 0px, -438px, 0px, 136px, 104px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-diferencia-fidelitzaci@2x', );
$icon-diferencia-innovacio-2x-name: 'icon-diferencia-innovacio@2x';
$icon-diferencia-innovacio-2x-x: 148px;
$icon-diferencia-innovacio-2x-y: 0px;
$icon-diferencia-innovacio-2x-offset-x: -148px;
$icon-diferencia-innovacio-2x-offset-y: 0px;
$icon-diferencia-innovacio-2x-width: 132px;
$icon-diferencia-innovacio-2x-height: 142px;
$icon-diferencia-innovacio-2x-total-width: 692px;
$icon-diferencia-innovacio-2x-total-height: 496px;
$icon-diferencia-innovacio-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-diferencia-innovacio-2x: (148px, 0px, -148px, 0px, 132px, 142px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-diferencia-innovacio@2x', );
$icon-diferencia-logistica-2x-name: 'icon-diferencia-logistica@2x';
$icon-diferencia-logistica-2x-x: 280px;
$icon-diferencia-logistica-2x-y: 0px;
$icon-diferencia-logistica-2x-offset-x: -280px;
$icon-diferencia-logistica-2x-offset-y: 0px;
$icon-diferencia-logistica-2x-width: 158px;
$icon-diferencia-logistica-2x-height: 108px;
$icon-diferencia-logistica-2x-total-width: 692px;
$icon-diferencia-logistica-2x-total-height: 496px;
$icon-diferencia-logistica-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-diferencia-logistica-2x: (280px, 0px, -280px, 0px, 158px, 108px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-diferencia-logistica@2x', );
$icon-diferencia-posicionament-2x-name: 'icon-diferencia-posicionament@2x';
$icon-diferencia-posicionament-2x-x: 438px;
$icon-diferencia-posicionament-2x-y: 104px;
$icon-diferencia-posicionament-2x-offset-x: -438px;
$icon-diferencia-posicionament-2x-offset-y: -104px;
$icon-diferencia-posicionament-2x-width: 116px;
$icon-diferencia-posicionament-2x-height: 116px;
$icon-diferencia-posicionament-2x-total-width: 692px;
$icon-diferencia-posicionament-2x-total-height: 496px;
$icon-diferencia-posicionament-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-diferencia-posicionament-2x: (438px, 104px, -438px, -104px, 116px, 116px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-diferencia-posicionament@2x', );
$icon-error-form-2x-name: 'icon-error-form@2x';
$icon-error-form-2x-x: 574px;
$icon-error-form-2x-y: 452px;
$icon-error-form-2x-offset-x: -574px;
$icon-error-form-2x-offset-y: -452px;
$icon-error-form-2x-width: 38px;
$icon-error-form-2x-height: 34px;
$icon-error-form-2x-total-width: 692px;
$icon-error-form-2x-total-height: 496px;
$icon-error-form-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-error-form-2x: (574px, 452px, -574px, -452px, 38px, 34px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-error-form@2x', );
$icon-oferim-asessorament-2x-name: 'icon-oferim-asessorament@2x';
$icon-oferim-asessorament-2x-x: 280px;
$icon-oferim-asessorament-2x-y: 108px;
$icon-oferim-asessorament-2x-offset-x: -280px;
$icon-oferim-asessorament-2x-offset-y: -108px;
$icon-oferim-asessorament-2x-width: 140px;
$icon-oferim-asessorament-2x-height: 118px;
$icon-oferim-asessorament-2x-total-width: 692px;
$icon-oferim-asessorament-2x-total-height: 496px;
$icon-oferim-asessorament-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-oferim-asessorament-2x: (280px, 108px, -280px, -108px, 140px, 118px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-oferim-asessorament@2x', );
$icon-oferim-marca-2x-name: 'icon-oferim-marca@2x';
$icon-oferim-marca-2x-x: 0px;
$icon-oferim-marca-2x-y: 258px;
$icon-oferim-marca-2x-offset-x: 0px;
$icon-oferim-marca-2x-offset-y: -258px;
$icon-oferim-marca-2x-width: 106px;
$icon-oferim-marca-2x-height: 144px;
$icon-oferim-marca-2x-total-width: 692px;
$icon-oferim-marca-2x-total-height: 496px;
$icon-oferim-marca-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-oferim-marca-2x: (0px, 258px, 0px, -258px, 106px, 144px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-oferim-marca@2x', );
$icon-oferim-proximitat-2x-name: 'icon-oferim-proximitat@2x';
$icon-oferim-proximitat-2x-x: 438px;
$icon-oferim-proximitat-2x-y: 220px;
$icon-oferim-proximitat-2x-offset-x: -438px;
$icon-oferim-proximitat-2x-offset-y: -220px;
$icon-oferim-proximitat-2x-width: 112px;
$icon-oferim-proximitat-2x-height: 114px;
$icon-oferim-proximitat-2x-total-width: 692px;
$icon-oferim-proximitat-2x-total-height: 496px;
$icon-oferim-proximitat-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-oferim-proximitat-2x: (438px, 220px, -438px, -220px, 112px, 114px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-oferim-proximitat@2x', );
$icon-oferim-referencies-2x-name: 'icon-oferim-referencies@2x';
$icon-oferim-referencies-2x-x: 0px;
$icon-oferim-referencies-2x-y: 144px;
$icon-oferim-referencies-2x-offset-x: 0px;
$icon-oferim-referencies-2x-offset-y: -144px;
$icon-oferim-referencies-2x-width: 154px;
$icon-oferim-referencies-2x-height: 114px;
$icon-oferim-referencies-2x-total-width: 692px;
$icon-oferim-referencies-2x-total-height: 496px;
$icon-oferim-referencies-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-oferim-referencies-2x: (0px, 144px, 0px, -144px, 154px, 114px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-oferim-referencies@2x', );
$icon-oferim-rendibilitat-2x-name: 'icon-oferim-rendibilitat@2x';
$icon-oferim-rendibilitat-2x-x: 154px;
$icon-oferim-rendibilitat-2x-y: 144px;
$icon-oferim-rendibilitat-2x-offset-x: -154px;
$icon-oferim-rendibilitat-2x-offset-y: -144px;
$icon-oferim-rendibilitat-2x-width: 106px;
$icon-oferim-rendibilitat-2x-height: 112px;
$icon-oferim-rendibilitat-2x-total-width: 692px;
$icon-oferim-rendibilitat-2x-total-height: 496px;
$icon-oferim-rendibilitat-2x-image: 'img/spritesheet@2x.png?1499174236134';
$icon-oferim-rendibilitat-2x: (154px, 144px, -154px, -144px, 106px, 112px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'icon-oferim-rendibilitat@2x', );
$logo-barra-capraboacasa-2x-name: 'logo-barra-capraboacasa@2x';
$logo-barra-capraboacasa-2x-x: 628px;
$logo-barra-capraboacasa-2x-y: 288px;
$logo-barra-capraboacasa-2x-offset-x: -628px;
$logo-barra-capraboacasa-2x-offset-y: -288px;
$logo-barra-capraboacasa-2x-width: 54px;
$logo-barra-capraboacasa-2x-height: 50px;
$logo-barra-capraboacasa-2x-total-width: 692px;
$logo-barra-capraboacasa-2x-total-height: 496px;
$logo-barra-capraboacasa-2x-image: 'img/spritesheet@2x.png?1499174236134';
$logo-barra-capraboacasa-2x: (628px, 288px, -628px, -288px, 54px, 50px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'logo-barra-capraboacasa@2x', );
$logo-barra-chefcaprabo-2x-name: 'logo-barra-chefcaprabo@2x';
$logo-barra-chefcaprabo-2x-x: 574px;
$logo-barra-chefcaprabo-2x-y: 424px;
$logo-barra-chefcaprabo-2x-offset-x: -574px;
$logo-barra-chefcaprabo-2x-offset-y: -424px;
$logo-barra-chefcaprabo-2x-width: 54px;
$logo-barra-chefcaprabo-2x-height: 28px;
$logo-barra-chefcaprabo-2x-total-width: 692px;
$logo-barra-chefcaprabo-2x-total-height: 496px;
$logo-barra-chefcaprabo-2x-image: 'img/spritesheet@2x.png?1499174236134';
$logo-barra-chefcaprabo-2x: (574px, 424px, -574px, -424px, 54px, 28px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'logo-barra-chefcaprabo@2x', );
$logo-barra-matrona-2x-name: 'logo-barra-matrona@2x';
$logo-barra-matrona-2x-x: 614px;
$logo-barra-matrona-2x-y: 384px;
$logo-barra-matrona-2x-offset-x: -614px;
$logo-barra-matrona-2x-offset-y: -384px;
$logo-barra-matrona-2x-width: 46px;
$logo-barra-matrona-2x-height: 34px;
$logo-barra-matrona-2x-total-width: 692px;
$logo-barra-matrona-2x-total-height: 496px;
$logo-barra-matrona-2x-image: 'img/spritesheet@2x.png?1499174236134';
$logo-barra-matrona-2x: (614px, 384px, -614px, -384px, 46px, 34px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'logo-barra-matrona@2x', );
$logo-barra-miclub-caprabo-2x-name: 'logo-barra-miclub-caprabo@2x';
$logo-barra-miclub-caprabo-2x-x: 574px;
$logo-barra-miclub-caprabo-2x-y: 288px;
$logo-barra-miclub-caprabo-2x-offset-x: -574px;
$logo-barra-miclub-caprabo-2x-offset-y: -288px;
$logo-barra-miclub-caprabo-2x-width: 54px;
$logo-barra-miclub-caprabo-2x-height: 50px;
$logo-barra-miclub-caprabo-2x-total-width: 692px;
$logo-barra-miclub-caprabo-2x-total-height: 496px;
$logo-barra-miclub-caprabo-2x-image: 'img/spritesheet@2x.png?1499174236134';
$logo-barra-miclub-caprabo-2x: (574px, 288px, -574px, -288px, 54px, 50px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'logo-barra-miclub-caprabo@2x', );
$logo-barra-viajes-caprabo-2x-name: 'logo-barra-viajes-caprabo@2x';
$logo-barra-viajes-caprabo-2x-x: 574px;
$logo-barra-viajes-caprabo-2x-y: 384px;
$logo-barra-viajes-caprabo-2x-offset-x: -574px;
$logo-barra-viajes-caprabo-2x-offset-y: -384px;
$logo-barra-viajes-caprabo-2x-width: 40px;
$logo-barra-viajes-caprabo-2x-height: 40px;
$logo-barra-viajes-caprabo-2x-total-width: 692px;
$logo-barra-viajes-caprabo-2x-total-height: 496px;
$logo-barra-viajes-caprabo-2x-image: 'img/spritesheet@2x.png?1499174236134';
$logo-barra-viajes-caprabo-2x: (574px, 384px, -574px, -384px, 40px, 40px, 692px, 496px, 'img/spritesheet@2x.png?1499174236134', 'logo-barra-viajes-caprabo@2x', );
$spritesheet-width: 346px;
$spritesheet-height: 248px;
$spritesheet-image: 'img/spritesheet.png?1499174236134';
$spritesheet-sprites: ($boto-mes-info, $clau-acces, $fletxa-claus-exit, $icon-dades-franquicies, $icon-dades-punts-venda, $icon-demanem-emprenedor, $icon-demanem-fidelitat, $icon-demanem-valors, $icon-diferencia-experiencia, $icon-diferencia-fidelitzaci, $icon-diferencia-innovacio, $icon-diferencia-logistica, $icon-diferencia-posicionament, $icon-error-form, $icon-oferim-asessorament, $icon-oferim-marca, $icon-oferim-proximitat, $icon-oferim-referencies, $icon-oferim-rendibilitat, $logo-barra-capraboacasa, $logo-barra-chefcaprabo, $logo-barra-matrona, $logo-barra-miclub-caprabo, $logo-barra-viajes-caprabo, );
$spritesheet: (346px, 248px, 'img/spritesheet.png?1499174236134', $spritesheet-sprites, );
$retina-spritesheet-width: 692px;
$retina-spritesheet-height: 496px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1499174236134';
$retina-spritesheet-sprites: ($boto-mes-info-2x, $clau-acces-2x, $fletxa-claus-exit-2x, $icon-dades-franquicies-2x, $icon-dades-punts-venda-2x, $icon-demanem-emprenedor-2x, $icon-demanem-fidelitat-2x, $icon-demanem-valors-2x, $icon-diferencia-experiencia-2x, $icon-diferencia-fidelitzaci-2x, $icon-diferencia-innovacio-2x, $icon-diferencia-logistica-2x, $icon-diferencia-posicionament-2x, $icon-error-form-2x, $icon-oferim-asessorament-2x, $icon-oferim-marca-2x, $icon-oferim-proximitat-2x, $icon-oferim-referencies-2x, $icon-oferim-rendibilitat-2x, $logo-barra-capraboacasa-2x, $logo-barra-chefcaprabo-2x, $logo-barra-matrona-2x, $logo-barra-miclub-caprabo-2x, $logo-barra-viajes-caprabo-2x, );
$retina-spritesheet: (692px, 496px, 'img/spritesheet@2x.png?1499174236134', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$boto-mes-info-group-name: 'boto-mes-info';
$boto-mes-info-group: ('boto-mes-info', $boto-mes-info, $boto-mes-info-2x, );
$clau-acces-group-name: 'clau-acces';
$clau-acces-group: ('clau-acces', $clau-acces, $clau-acces-2x, );
$fletxa-claus-exit-group-name: 'fletxa-claus-exit';
$fletxa-claus-exit-group: ('fletxa-claus-exit', $fletxa-claus-exit, $fletxa-claus-exit-2x, );
$icon-dades-franquicies-group-name: 'icon-dades-franquicies';
$icon-dades-franquicies-group: ('icon-dades-franquicies', $icon-dades-franquicies, $icon-dades-franquicies-2x, );
$icon-dades-punts-venda-group-name: 'icon-dades-punts-venda';
$icon-dades-punts-venda-group: ('icon-dades-punts-venda', $icon-dades-punts-venda, $icon-dades-punts-venda-2x, );
$icon-demanem-emprenedor-group-name: 'icon-demanem-emprenedor';
$icon-demanem-emprenedor-group: ('icon-demanem-emprenedor', $icon-demanem-emprenedor, $icon-demanem-emprenedor-2x, );
$icon-demanem-fidelitat-group-name: 'icon-demanem-fidelitat';
$icon-demanem-fidelitat-group: ('icon-demanem-fidelitat', $icon-demanem-fidelitat, $icon-demanem-fidelitat-2x, );
$icon-demanem-valors-group-name: 'icon-demanem-valors';
$icon-demanem-valors-group: ('icon-demanem-valors', $icon-demanem-valors, $icon-demanem-valors-2x, );
$icon-diferencia-experiencia-group-name: 'icon-diferencia-experiencia';
$icon-diferencia-experiencia-group: ('icon-diferencia-experiencia', $icon-diferencia-experiencia, $icon-diferencia-experiencia-2x, );
$icon-diferencia-fidelitzaci-group-name: 'icon-diferencia-fidelitzaci';
$icon-diferencia-fidelitzaci-group: ('icon-diferencia-fidelitzaci', $icon-diferencia-fidelitzaci, $icon-diferencia-fidelitzaci-2x, );
$icon-diferencia-innovacio-group-name: 'icon-diferencia-innovacio';
$icon-diferencia-innovacio-group: ('icon-diferencia-innovacio', $icon-diferencia-innovacio, $icon-diferencia-innovacio-2x, );
$icon-diferencia-logistica-group-name: 'icon-diferencia-logistica';
$icon-diferencia-logistica-group: ('icon-diferencia-logistica', $icon-diferencia-logistica, $icon-diferencia-logistica-2x, );
$icon-diferencia-posicionament-group-name: 'icon-diferencia-posicionament';
$icon-diferencia-posicionament-group: ('icon-diferencia-posicionament', $icon-diferencia-posicionament, $icon-diferencia-posicionament-2x, );
$icon-error-form-group-name: 'icon-error-form';
$icon-error-form-group: ('icon-error-form', $icon-error-form, $icon-error-form-2x, );
$icon-oferim-asessorament-group-name: 'icon-oferim-asessorament';
$icon-oferim-asessorament-group: ('icon-oferim-asessorament', $icon-oferim-asessorament, $icon-oferim-asessorament-2x, );
$icon-oferim-marca-group-name: 'icon-oferim-marca';
$icon-oferim-marca-group: ('icon-oferim-marca', $icon-oferim-marca, $icon-oferim-marca-2x, );
$icon-oferim-proximitat-group-name: 'icon-oferim-proximitat';
$icon-oferim-proximitat-group: ('icon-oferim-proximitat', $icon-oferim-proximitat, $icon-oferim-proximitat-2x, );
$icon-oferim-referencies-group-name: 'icon-oferim-referencies';
$icon-oferim-referencies-group: ('icon-oferim-referencies', $icon-oferim-referencies, $icon-oferim-referencies-2x, );
$icon-oferim-rendibilitat-group-name: 'icon-oferim-rendibilitat';
$icon-oferim-rendibilitat-group: ('icon-oferim-rendibilitat', $icon-oferim-rendibilitat, $icon-oferim-rendibilitat-2x, );
$logo-barra-capraboacasa-group-name: 'logo-barra-capraboacasa';
$logo-barra-capraboacasa-group: ('logo-barra-capraboacasa', $logo-barra-capraboacasa, $logo-barra-capraboacasa-2x, );
$logo-barra-chefcaprabo-group-name: 'logo-barra-chefcaprabo';
$logo-barra-chefcaprabo-group: ('logo-barra-chefcaprabo', $logo-barra-chefcaprabo, $logo-barra-chefcaprabo-2x, );
$logo-barra-matrona-group-name: 'logo-barra-matrona';
$logo-barra-matrona-group: ('logo-barra-matrona', $logo-barra-matrona, $logo-barra-matrona-2x, );
$logo-barra-miclub-caprabo-group-name: 'logo-barra-miclub-caprabo';
$logo-barra-miclub-caprabo-group: ('logo-barra-miclub-caprabo', $logo-barra-miclub-caprabo, $logo-barra-miclub-caprabo-2x, );
$logo-barra-viajes-caprabo-group-name: 'logo-barra-viajes-caprabo';
$logo-barra-viajes-caprabo-group: ('logo-barra-viajes-caprabo', $logo-barra-viajes-caprabo, $logo-barra-viajes-caprabo-2x, );
$retina-groups: ($boto-mes-info-group, $clau-acces-group, $fletxa-claus-exit-group, $icon-dades-franquicies-group, $icon-dades-punts-venda-group, $icon-demanem-emprenedor-group, $icon-demanem-fidelitat-group, $icon-demanem-valors-group, $icon-diferencia-experiencia-group, $icon-diferencia-fidelitzaci-group, $icon-diferencia-innovacio-group, $icon-diferencia-logistica-group, $icon-diferencia-posicionament-group, $icon-error-form-group, $icon-oferim-asessorament-group, $icon-oferim-marca-group, $icon-oferim-proximitat-group, $icon-oferim-referencies-group, $icon-oferim-rendibilitat-group, $logo-barra-capraboacasa-group, $logo-barra-chefcaprabo-group, $logo-barra-matrona-group, $logo-barra-miclub-caprabo-group, $logo-barra-viajes-caprabo-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
