.basic {
  margin-bottom: vr(1);
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  legend {
    padding-bottom: vr(.5);
    width: 100%;
    color: $primary-color;
    @include bold;
    @include typi(t3);
    + p {
      @include typi(s1);
      @include italic;
    }
  }
  label {
    display: block;
    margin-bottom: vr(.25);
    a {
      color: $primary-color;
      &:hover {
        text-decoration: none;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  textarea, select {
    @include typi(s1);
    background-color: #e7e7e7;
    width: 100%;
    padding: vr(.25) vr(.5);
    margin-bottom: vr(1);
    &.error {
      border: 1px solid red;
    }
  }
  // *:last-child {
  //   margin-bottom: 0;
  // }
  button {
    margin: vr(1) 0 0;
  }
  a {
    color: $primary-color;
    &:hover {
      text-decoration: none;
    }
  }
}

// validació
label.error {
  @include typi(s1);
  margin-top: vr(-.5);
  margin-bottom: vr(1);
  color: red;
  &:before {
    content: '';
    @extend .icon-error-form;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
  }
}

// popup form
.getinfo-form {
  background: #fff;
  padding: vr(1);
  //max-width: 650px;
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  @media #{$medium-up} {
    //padding: vr(2);
  }
  h2 {
    text-transform: none;
    margin-bottom: vr(.75);
    @include regular;
  }
}
@media #{$medium-up} {
  label.checkbox {
    margin-top: vr(1.5);
  }
}

// formulari ocult quan hi ha js
.js .mfp-hide {
  display: none !important;
}
.no-js .mfp-hide {
  display: block !important;
}

// feedback
.getinfo-success,
.getinfo-error {
  p {
    @include typi(t3);
    margin: vr(1) 0 0;
  }
}
