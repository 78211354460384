.dades {
  @media only screen and (min-width: em-calc(600)) {
    @include cf;
  }
  &__block {
    text-align: center;
    @media only screen and (min-width: em-calc(700)) {
      text-align: left;
    }
    p {
      @media only screen and (min-width: em-calc(600)) {
        margin-bottom: 0;
      }
    }
    
    &--punts {
      @media only screen and (min-width: em-calc(600)) {
        width: 65%;
        float: left;
        border-right: 4px solid #bdbdbd;
        padding-right: vr(1);
      }
      @media only screen and (min-width: em-calc(850)) {
        width: 60%;
      }
      @media only screen and (min-width: em-calc(1000)) {
        width: 65%;
      }
      .dades__num {
        &:before {
          @extend .icon-dades-punts-venda;
        }
        @media only screen and (min-width: em-calc(700)) {
          float: left;
          margin-right: vr(1);
          margin-bottom: 0;
          vertical-align: middle;
        }
      }
    }
    &--franquicies {
      @media only screen and (min-width: em-calc(600)) {
        width: 35%;
        float: right;
        padding-left: vr(1);
      }
      @media only screen and (min-width: em-calc(850)) {
        width: 40%;
      }
      @media only screen and (min-width: em-calc(1000)) {
        width: 35%;
      }
      .dades__num {
        &:before {
          @extend .icon-dades-franquicies;
        }
        @media only screen and (min-width: em-calc(700)) {
          float: left;
          margin-right: vr(.5);
          margin-bottom: 0;
        }
      }
      .dades__text {
        @media only screen and (min-width: em-calc(700)) {
          position: relative;
          top: 1.5em;
        } 
      }
    }
  }
  &__num {
    display: block;
    color: $primary-color;
    margin-bottom: vr(.25);
    @include bold;
    @include typi(data);
    &:before {
      content: '';
      display: inline-block;
      margin-right: 10px;
    }
  }
}