.franq {
  border-top: 1px solid #cbcbcb;
  padding: vr(1) 0;
  @media only screen and (min-width: em-calc(600)) {
    border-top: 0;
    border-left: 1px solid #cbcbcb;
    width: 33.333%;
    float: left;
    padding-left: $column-gutter/2;
    padding-right: $column-gutter/2;
    padding-bottom: vr(3.5);
    position: relative;
    &:last-child {
      border-right: 1px solid #cbcbcb;
    }
    a.more {
      position: absolute;
      bottom: vr(1);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
