.js .nav-wrapper {
  &--desktop {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  &--mobile {
    @media #{$medium-up} {
      display: none;
    }
  }
  &.scroll-to-fixed-fixed {
    // background-color: #006c8c;
    // width: 100%;
    // @media #{$medium-up} {
    //   margin-top: 0;
    // }
  }
}
// .logo-ortoarea-fixed {
//   display: none;
//   @media #{$medium-up} {
//     .scroll-to-fixed-fixed & {
//       display: inline-block;
//       margin-right: 5px;
//     }
//   }
// }
// .menu__container {
//   @media #{$medium-up} {
//     .scroll-to-fixed-fixed & {
//       display: inline-block;
//       margin-right: 5px;
//     }
//   }
// }
.menu-button {
  position: absolute;
  right: vr(1);
  top: 0;
  z-index: 3;
  &__text {
    @include typi(s2);
    color: #fff;
    text-transform: uppercase;
    margin-right: 8px;
  }
  @media #{$medium-up} {
    .js & {
      display: none;
    }
  }
}
.hamburger-box {
  vertical-align: middle;
}

@media #{$small-only} {
  button[aria-expanded="false"] + ul.menu {
    display: none;
  }
}
@media #{$small-only} {
  button[aria-expanded="false"] + .menu {
    display: none;
  }
}
.js .menu {
  @media #{$small-only} {
    &.js-open {
      position: absolute;
      opacity: 1;
      left: 0;
      top: 35px;
      width: 100%;
      background: $secondary-color;
      z-index: 2;
    }
  }
}
.menu {
  padding: vr(2);
  @media #{$medium-up} {
    text-align: center;
    padding: 0;
  }
}
.site-menu {
  @include typi(t4);
  list-style: none;
  margin: 0;
  text-align: center;
  @media #{$medium-up} {
    @include typi(s1);
  }

  &__item {
    @include typi(centres);
    @include bold;
    display: block;
    margin-bottom: rem-calc(5);
  }
  &__tagline {
    display: block;
    @include typi(s1);
  }

  a {
    text-decoration: none;
    display: block;
    padding: .3em;
    color: $body-font-color;
    background-color: #fff;
    border-radius: 100px;
    margin-bottom: vr(1);
    &.info {
      background-color: $primary-color;
      color: #fff;
    }
    &.centre {
      padding: vr(.5);
    }
    @media #{$medium-up} {
      border: 2px solid #cdcdcd;
      margin-bottom: vr(.75);
      transition: background-color .1s ease-out, border-color .1s ease-out, color .1s ease-out;
      &:hover,
      &:active {
        color: #fff;
        background-color: $primary-color;
        border: 2px solid $primary-color;
      }
      &[aria-current="page"] {
        color: #fff;
        background-color: $primary-color;
        border-color: $primary-color;
      }
      &.info {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: #fff;
        &:hover {
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
  }
}
.acces {
  text-align: center;
  @media #{$medium-up} {
    @include typi(s1);
  }
  a {
    color: #fff;
    text-decoration: none;
    @media #{$medium-up} {
      color: $primary-color;
    }
    &:before {
      content: '';
      @extend .clau-acces;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
