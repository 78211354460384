@include typi-init;
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}
h1,
.t1 {
  @include typi('t1');
  @include bold;
  text-align: center;
  margin-bottom: vr(1);
  text-transform: uppercase;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
h2,
.t2 {
  @include typi('t2');
  @include light;
  text-transform: uppercase;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: vr(.75);
  margin-bottom: vr(1.25);
  &:not(:first-child) {
    margin-top: vr(2);
  }
}
h3,
.t3 {
  @include typi('t3');
  
}
h4,
.t4 {
  @include typi('t4');
}

p, ol, ul, dl, figure, {
  margin: 0 0 vr(.75);
}
dl {
  @include cf;
  div {
    width: 50%;
    float: left;
    border-left: 1px solid;
    padding: 0 vr(1) 0 vr(.5);
    margin-bottom: vr(1);
    @media #{$large-up} {
      width: 25%;
      margin-bottom: 0;
    }
  }
}
dt {
  .no-js & {
    margin-top: vr(1);
  }
}
dd {
  margin-left: 0;
}

ol {
  padding-left: 0;
  list-style-position: outside;
  margin-left: vr(1.5);
  // @media #{$medium-up} {
  //   margin-left: vr(1.5);
  //   list-style-position: outside;
  //   padding-left: 0;
  // }
}
main ul {
  list-style: none;
  li {
    padding-left: vr(.75);
    position: relative;
    margin-bottom: vr(.25);
    &:before {
      content: '';
      width: .5em;
      height: .5em;
      display: block;
      left: 0;
      top: .45em;
      border-radius: 100px;
      position: absolute;
      background-color: $primary-color;
    }
  }
} 
::selection {
  color: #fff;
  background: lighten($primary-color,30);
}
strong, b {
  @include bold;
}
em, i {
  @include italic;
}
hr {
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  margin: vr(1) 0;
  height: 0;
}
main {
  a {
    color: $primary-color;
    text-decoration: none;
    border-bottom: rem-calc(1) solid $primary-color;
    transition: border-width .1s,;

    &:hover {
      border: 0;
    }
  }
}
.main-header__lead {
  @include bold;
  // @include typi(t3);
}