.actions {
  text-align: center;
  display: table;
  span {
    display: table-row;
  }
  a {
    display: table-cell;
    //background-color: $secondary-color;
    background-color: #e32119;
    border: 0;
    color: #fff;
    vertical-align: middle;
    transition-property:
      transform,
      border,
      background-color,
      color;
    transition-duration: .2s;
    transition-timing-function: ease-in;
    &:hover {
      transform: scale(1.025);
      //border: 3px solid $secondary-color;
      border: 3px solid #e32119;
      background-color: #fff;
      //color: $secondary-color;
      color: #e32119;
    }

    @media only screen and (max-width: em-calc(499)) {
        @include typi(s1);
        width: 100%;
        padding: 1em;
        font-size: 1.4em;
    }
    @media only screen and (min-width: em-calc(500)) {
        margin-bottom: vr(1);
        width: rem-calc(105);
        height: rem-calc(105);
        border-radius: 100px;
    }
  }


  @media only screen and (max-width: em-calc(499)) {
    width: 100%;
    left: 0;
    position: fixed;
    bottom: 0;
    z-index: 1;
  }
  @media only screen and (min-width: em-calc(500)) {
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    z-index: 1;
    right: $column-gutter/2;
    top: 50%;
    transform: translateY(-50%);
  }
}
