// Resets margins and paddings for headers, ul, ol and p
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders
// and radius of all form items.
// ============================
input,
textarea,
button {
  border: 0;
  border-radius: 0;
  outline: none;
  appearance: none;

  &:hover,
  &:active,
  &:focus {
    // outline: none;
  }
}

// Use border-box for everything
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

img { -ms-interpolation-mode: bicubic; }
// Get rid of gap under images by making them display: inline-block; by default
img {
  display: inline-block;
  vertical-align: middle;
}

// webfonts declarations
@font-face {
    font-family: 'lato_black';
    src: url('/media/fonts/Lato/Lato-Black.eot');
    src: url('/media/fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Lato/Lato-Black.woff2') format('woff2'),
         url('/media/fonts/Lato/Lato-Black.woff') format('woff'),
         url('/media/fonts/Lato/Lato-Black.ttf') format('truetype'),
         url('/media/fonts/Lato/Lato-Black.svg#lato_blackregular') format('svg');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'lato_bold';
    src: url('/media/fonts/Lato/Lato-Bold.eot');
    src: url('/media/fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Lato/Lato-Bold.woff2') format('woff2'),
         url('/media/fonts/Lato/Lato-Bold.woff') format('woff'),
         url('/media/fonts/Lato/Lato-Bold.ttf') format('truetype'),
         url('/media/fonts/Lato/Lato-Bold.svg#latobold') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'lato_italic';
    src: url('/media/fonts/Lato/Lato-Italic.eot');
    src: url('/media/fonts/Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Lato/Lato-Italic.woff2') format('woff2'),
         url('/media/fonts/Lato/Lato-Italic.woff') format('woff'),
         url('/media/fonts/Lato/Lato-Italic.ttf') format('truetype'),
         url('/media/fonts/Lato/Lato-Italic.svg#latoitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'lato_regular';
    src: url('/media/fonts/Lato/Lato-Regular.eot');
    src: url('/media/fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Lato/Lato-Regular.woff2') format('woff2'),
         url('/media/fonts/Lato/Lato-Regular.woff') format('woff'),
         url('/media/fonts/Lato/Lato-Regular.ttf') format('truetype'),
         url('/media/fonts/Lato/Lato-Regular.svg#Lato') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'lato_lightitalic';
    src: url('/media/fonts/Lato/Lato-Light-Italic.eot');
    src: url('/media/fonts/Lato/Lato-Light-Italic.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Lato/Lato-Light-Italic.woff2') format('woff2'),
         url('/media/fonts/Lato/Lato-Light-Italic.woff') format('woff'),
         url('/media/fonts/Lato/Lato-Light-Italic.ttf') format('truetype'),
         url('/media/fonts/Lato/Lato-Light-Italic.svg#lato_lightitalic') format('svg');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'lato_light';
    src: url('/media/fonts/Lato/Lato-Light.eot');
    src: url('/media/fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/Lato/Lato-Light.woff2') format('woff2'),
         url('/media/fonts/Lato/Lato-Light.woff') format('woff'),
         url('/media/fonts/Lato/Lato-Light.ttf') format('truetype'),
         url('/media/fonts/Lato/Lato-Light.svg#lato_lightregular') format('svg');
    font-weight: 300;
    font-style: normal;
}

// Sprites
@import 'sprites';
@include sprites($spritesheet-sprites);
// Creates all retina sprites
@include retina-sprites($retina-groups);