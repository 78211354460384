.picts-list {
  @include block-grid($per-row: 1);
  @media only screen and (min-width: em-calc(500)) {
    @include block-grid($per-row: 2);
  }
  @media only screen and (min-width: em-calc(768)) {
    @include block-grid($per-row: 3);
  }
  li {
    text-align: center;
    margin-bottom: vr(1);
    &:before {
      display: block;
      position: static;
      border-radius: 0;
      background-color: transparent;
      margin: 0 auto;
    }
    span {
      display: block;
      padding-top: vr(.5);
    }
  }

  // icones
  &--01 {
    &:before {
      @extend .icon-oferim-proximitat;
      @media only screen and (min-width: em-calc(500)) {
        $spacing: $icon-oferim-marca-height - $icon-oferim-proximitat-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
  &--02 {
    &:before {
      @extend .icon-oferim-marca;
    }
  }
  &--03 {
    &:before {
      @extend .icon-oferim-referencies;
      @media only screen and (min-width: em-calc(768)) {
        $spacing: $icon-oferim-marca-height - $icon-oferim-referencies-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
  &--04 {
    &:before {
      @extend .icon-oferim-rendibilitat;
      @media only screen and (min-width: em-calc(500)) {
        margin-bottom: 1px !important;
      }
      @media only screen and (min-width: em-calc(768)) {
        $spacing: $icon-oferim-asessorament-height - $icon-oferim-rendibilitat-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
  &--05 {
    &:before {
      @extend .icon-oferim-asessorament;
    }
  }
  &--06 {
    &:before {
      @extend .icon-diferencia-experiencia;
    }
  }
  &--07 {
    &:before {
      @extend .icon-diferencia-innovacio;
      @media only screen and (min-width: em-calc(500)) {
        margin-bottom: 1px !important;
      }
    }
  }
  &--08 {
    &:before {
      @extend .icon-diferencia-fidelitzaci;
      @media only screen and (min-width: em-calc(500)) {
        $spacing: $icon-diferencia-posicionament-height - $icon-diferencia-fidelitzaci-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
      @media only screen and (min-width: em-calc(768)) {
        $spacing: $icon-diferencia-experiencia-height - $icon-diferencia-fidelitzaci-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
  &--09 {
    &:before {
      @extend .icon-diferencia-posicionament;
    }
  }
  &--10 {
    &:before {
      @extend .icon-diferencia-logistica;
      @media only screen and (min-width: em-calc(768)) {
        $spacing: $icon-diferencia-posicionament-height - $icon-diferencia-logistica-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
  &--11 {
    &:before {
      @extend .icon-demanem-emprenedor;
    }
  }
  &--12 {
    &:before {
      @extend .icon-demanem-fidelitat;
      @media only screen and (min-width: em-calc(500)) {
        $spacing: $icon-demanem-emprenedor-height - $icon-demanem-fidelitat-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
  &--13 {
    &:before {
      @extend .icon-demanem-valors;
      @media only screen and (min-width: em-calc(768)) {
        $spacing: $icon-demanem-emprenedor-height - $icon-demanem-valors-height;
        margin-bottom: $spacing/2 !important;
        margin-top: $spacing/2 !important;
      }
    }
  }
} 
