.caprabo-menu {
	display: none;
	@include bold;
	@include typi(s2);
	float: right;
	margin: 0;
	li {
		display: inline-block;
		margin-left: vr(1);
	}
	&--acasa {
		a:before {
			//@extend .logo-barra-capraboacasa;
            background-image: url('img/capraboacasa.svg') ;
            height: 35px;
            width: 35px;
		}
	}
	&--club {
		a:before {
			@extend .logo-barra-miclub-caprabo;
		}
	}
	&--chef {
		a:before {
			//@extend .logo-barra-chefcaprabo;
            background-image: url('img/chefcaprabo.svg') ;
            height: 32px;
            width: 65px;
		}
	}
	&--matrona {
		a:before {
			//@extend .logo-barra-matrona;
            background-image: url('img/blog.svg') ;
            height: 35px;
            width: 35px;
		}
	}
	&--viajes {
		a:before {
			@extend .logo-barra-viajes-caprabo;
		}
	}
	/*&--cataleg {
		a:before {
			@extend .logo-barra-cataleg;
		}
	}*/
	a {
		//color: #fff;
        color: $primary-color;
		text-decoration: none;
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			margin-right: 8px;
		}
		&:hover {text-decoration: underline;}
	}
	@media #{$medium-up} {
		display: block;
	}
}



.caprabo-lang {
    display: none;
    @include bold;
    @include typi(s2);
    float: right;
    margin: 0;
    margin-top: 1em;
    text-align: right;
    width: 50%;
    li {
        display: inline-block;
        &.last:before {
            content: '|';
            display: inline-block;
            vertical-align: middle;
        }
        &.last a {
            margin-right: 0;
        }
    }
    a {
        //color: #fff;
        color: #000;
        text-decoration: none;
        margin: 0 vr(.5);
        &:hover {text-decoration: underline;}
    }
    @media #{$medium-up} {
        display: block;
    }
}
