.top {
  display: none;
  position: fixed;
  right: $column-gutter/2;
  bottom: vr(1);
  transition: opacity .1s;
  z-index: 2;
  @media #{$small-only} {
    width: 42px;
    height: 42px;
  }
  @media only screen and (max-width: em-calc(499)) {
    display: none!important;
  }
  &:hover,
  &:focus {
    opacity: .8;
  }
}
